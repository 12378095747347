import logger from '~/shared/utils/logger';
import { gql } from '~/techstyle-shared/react-graphql';
import {
  BUNDLE_PRODUCT_MAIN_DETAILS_FRAGMENT,
  BUNDLE_PRODUCT_VIDEO_FRAGMENT,
  BUNDLE_PRODUCT_PRICE_FRAGMENT,
  BUNDLE_PRODUCT_REVIEW_SUMMARY_FRAGMENT,
  INDIVIDUAL_PRODUCT_MAIN_DETAILS_FRAGMENT,
  INDIVIDUAL_PRODUCT_PRICE_FRAGMENT,
  INDIVIDUAL_PRODUCT_VIDEO_FRAGMENT,
  INDIVIDUAL_PRODUCT_REVIEW_SUMMARY_FRAGMENT,
  INDIVIDUAL_PRODUCT_SELECTORS_FRAGMENT,
  setupUseProductQueryAndPreload,
} from '~/techstyle-shared/react-products';

import useLoadProductFields from './useLoadProductFields';

const debug = logger.extend('useProductQueryAndPreload');

/**
 * defining image fragments instead of importing to include new embroidery field
 */
const IMAGE_SET_FRAGMENT = gql`
  fragment ProductImageSetParts on ImageSet {
    images {
      isVideo
      videoPath
      angle
      sizes {
        height
        width
        url
      }
    }
    model {
      firstName
      lastName
      heightInInches
      id
      braSize
      topSize
      bottomSize
      braCup
      braBand
      waist
      inseam
    }
  }
`;

const INDIVIDUAL_PRODUCT_PRODUCT_IMAGES_FRAGMENT = gql`
  ${IMAGE_SET_FRAGMENT}
  fragment IndividualProductImagesParts on IndividualProduct {
    imageSets {
      default {
        ...ProductImageSetParts
      }
      plus {
        ...ProductImageSetParts
      }
      memberModels {
        ...ProductImageSetParts
      }
      embroidery {
        images {
          sizes {
            url
          }
        }
      }
    }
  }
`;

export const BUNDLE_PRODUCT_PRODUCT_IMAGES_FRAGMENT = gql`
  ${IMAGE_SET_FRAGMENT}
  fragment BundleProductImagesParts on BundleProduct {
    imageSets {
      default {
        ...ProductImageSetParts
      }
      plus {
        ...ProductImageSetParts
      }
      memberModels {
        ...ProductImageSetParts
      }
    }
  }
`;

const baseQuery = `
{
  ... on IndividualProduct {
    ...IndividualProductAllPreloadParts
    isAvailable
    membershipBrandId
    requiredUserStatus
    styleCode
    styleLabel
    skus {
      warehouseInventory @include(if: $withWarehouseInv) {
        id
        quantity
      }
      itemNumber
			availableQuantityPreorder
      swatchType @include(if: $includeSwatchType)
    }
    swatches {
      urlEcatDefault
      urlEcatPlus
      url
    }
    description
    defaultCategoryLabel
    details {
      fabricContent
      fabricType
      features
      fabricTagId
    }
    metadata {
      description
      title
    }
    associatedBundleProductIdsList
    activeFeaturedProductLocationCodeList: activeFeaturedProductLocationCodes {
      featuredProductLocationCode: code
    }
    activeFeaturedProductLocationIdList: activeFeaturedProductLocationIds {
      featuredProductLocationId: id
    }
    endowmentAmount
    isEndowmentEligible
    isWarehouseProduct
    warehouseUnitPrice
    alias
    itemNumber
    styleCode
    styleLabel
    personalizations {
      productId
      templateLabel
      type
      customizationTemplateId
      customizationTypeId
      locations {
        locationId
        label
        thumbnail
        customizations {
          customizationId
          label
          options {
            optionId
            label
          }
        }
      }
      unitPrice
      promoPrice
    }
    datePreorderExpires
    isTokenOnly
  }
  ... on BundleProduct {
    ...BundleProductAllPreloadParts
    isAvailable
    requiredUserStatus
  	componentExclusions { sku }
    bundleComponentProducts {
      ...IndividualProductAllPreloadParts
      description
      defaultCategoryLabel
      details {
        fabricContent
        fabricType
        features
        fabricTagId
      }
      categoryIds
      associatedBundleProductIdsList
      skus {
        itemNumber
				availableQuantityPreorder
        swatchType @include(if: $includeSwatchType)
      }
      styleCode
      styleLabel
      personalizations {
        productId
        templateLabel
        type
        customizationTemplateId
        customizationTypeId
        locations {
          locationId
          label
          thumbnail
          customizations {
            customizationId
            label
            options {
              optionId
              label
            }
          }
        }
        unitPrice
        promoPrice
      }
    }
    description
    alias
    itemNumber
    metadata {
      description
      title
    }
    tokenRedemptionValue
    isTokenOnly
  }
}
`;

const INDIVIDUAL_PRODUCT_ALL_FRAGMENT = gql`
  ${INDIVIDUAL_PRODUCT_MAIN_DETAILS_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_PRICE_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_PRODUCT_IMAGES_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_VIDEO_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_REVIEW_SUMMARY_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_SELECTORS_FRAGMENT}
  fragment IndividualProductAllPreloadParts on IndividualProduct {
    ...IndividualProductMainParts
    ...IndividualProductPriceParts
    ...IndividualProductImagesParts
    ...IndividualProductVideoParts
    ...IndividualProductReviewSummaryParts
    ...IndividualProductSelectorsParts
  }
`;

const BUNDLE_PRODUCT_ALL_FRAGMENT = gql`
  ${BUNDLE_PRODUCT_MAIN_DETAILS_FRAGMENT}
  ${BUNDLE_PRODUCT_PRODUCT_IMAGES_FRAGMENT}
  ${BUNDLE_PRODUCT_VIDEO_FRAGMENT}
  ${BUNDLE_PRODUCT_PRICE_FRAGMENT}
  ${BUNDLE_PRODUCT_REVIEW_SUMMARY_FRAGMENT}
  fragment BundleProductAllPreloadParts on BundleProduct {
    ...BundleProductMainParts
    ...BundleProductImagesParts
    ...BundleProductVideoParts
    ...BundleProductPriceParts
    ...BundleProductReviewSummaryParts
  }
`;

// this query gets called first when the PDP loads
// it fetches the initially loaded product which is based on the permalink
const GET_PRODUCT_BY_PERMALINK_QUERY = gql`
  ${INDIVIDUAL_PRODUCT_ALL_FRAGMENT}
  ${BUNDLE_PRODUCT_ALL_FRAGMENT}
  query ProductByPermalink(
    $permalink: String,
    $fetchRelatedProducts: Boolean,
    $withWarehouseInv: Boolean! = false,
    $useRetailPriceForDiscount: Boolean! = false
    $includeSwatchType: Boolean! = false,
    $excludeSkus: [String!]
    $gridGender: String
    $membershipLevelGroup: String
    $filterOrphanedOptions: Boolean! = false
    $isWarehouseProduct: Boolean! = false
    $relatedProductsImageData: Boolean! = false
  ) {
    product: productByPermalink(
      permalink: $permalink
      fetchRelatedProducts: $fetchRelatedProducts
      useRetailPriceForDiscount: $useRetailPriceForDiscount
      includeSwatchType: $includeSwatchType
      excludeSkus: $excludeSkus
      gridGender: $gridGender
      membershipLevelGroup: $membershipLevelGroup
      filterOrphanedOptions: $filterOrphanedOptions
      isWarehouseProduct: $isWarehouseProduct
      relatedProductsImageData: $relatedProductsImageData
    ) ${baseQuery}
  }
`;

// this query is an alternative to GET_PRODUCT_BY_PERMALINK_QUERY
// it fetches the initially loaded product based on the product id instead of the permalink
const GET_PRODUCT_BY_PRODUCT_ID_QUERY = gql`
  ${INDIVIDUAL_PRODUCT_ALL_FRAGMENT}
  ${BUNDLE_PRODUCT_ALL_FRAGMENT}
  query ProductByProductId(
    $productId: Int,
    $fetchRelatedProducts: Boolean,
    $withWarehouseInv: Boolean! = false,
    $useRetailPriceForDiscount: Boolean! = false
    $includeSwatchType: Boolean! = false,
    $excludeSkus: [String!]
    $gridGender: String
    $membershipLevelGroup: String
    $filterOrphanedOptions: Boolean! = false,
    $isWarehouseProduct: Boolean! = false
  ) {
    product: productByProductId(
      productId: $productId
      fetchRelatedProducts: $fetchRelatedProducts
      useRetailPriceForDiscount: $useRetailPriceForDiscount
      includeSwatchType: $includeSwatchType
      excludeSkus: $excludeSkus
      gridGender: $gridGender
      membershipLevelGroup: $membershipLevelGroup
      filterOrphanedOptions: $filterOrphanedOptions
      isWarehouseProduct: $isWarehouseProduct
    ) ${baseQuery}
  }
`;

// NOTES:
// - this query gets called once to preload ALL related products(swatches/colors)
//   AND if a swatch/color failed to preload when a new swatch is selected
// - skus, attributes, swatches, and reviews removed from this query to improve performance
//   - they are shared from the initial product query
const GET_RELATED_PRODUCTS_BY_PRODUCT_IDS_QUERY = gql`
  ${INDIVIDUAL_PRODUCT_MAIN_DETAILS_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_PRICE_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_PRODUCT_IMAGES_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_VIDEO_FRAGMENT}
  query PreloadProductsByMasterProductIds(
    $productIds: [Int!]!
    $useRetailPriceForDiscount: Boolean! = false
    $isWarehouseProduct: Boolean! = false
  ) {
    products: masterProducts(
      productIds: $productIds
      useRetailPriceForDiscount: $useRetailPriceForDiscount
      isWarehouseProduct: $isWarehouseProduct
    ) {
      ... on IndividualProduct {
        ...IndividualProductMainParts
        ...IndividualProductPriceParts
        ...IndividualProductImagesParts
        ...IndividualProductVideoParts
        activeFeaturedProductLocationCodeList: activeFeaturedProductLocationCodes {
          featuredProductLocationCode: code
        }
        activeFeaturedProductLocationIdList: activeFeaturedProductLocationIds {
          featuredProductLocationId: id
        }
        isAvailable
        requiredUserStatus
        description
        details {
          fabricContent
          fabricType
          features
          fabricTagId
        }
        metadata {
          description
          title
        }
        associatedBundleProductIdsList
        styleCode
        styleLabel
        personalizations {
          unitPrice
          promoPrice
          productId
          templateLabel
          type
          customizationTemplateId
          customizationTypeId
          locations {
            locationId
            label
            thumbnail
            customizations {
              customizationId
              label
              options {
                optionId
                label
              }
            }
          }
        }
      }
    }
  }
`;

const quickViewBaseQuery = `
{
  ... on IndividualProduct {
    ...IndividualProductAllPreloadParts
    isAvailable
    requiredUserStatus
    skus {
      itemNumber
    }
    alias
    itemNumber
  }
  ... on BundleProduct {
    ...BundleProductAllPreloadParts
    isAvailable
    requiredUserStatus
    bundleComponentProducts {
      ...IndividualProductAllPreloadParts
      skus {
        itemNumber
        swatchType @include(if: $includeSwatchType)
      }
    }
    alias
    itemNumber
    tokenRedemptionValue
    isTokenOnly
  }
}
`;

export const GET_PRODUCT_BY_PERMALINK_QUERY_QUICKVIEW = gql`
  ${INDIVIDUAL_PRODUCT_ALL_FRAGMENT}
  ${BUNDLE_PRODUCT_ALL_FRAGMENT}
  query ProductByPermalink(
    $permalink: String,
    $fetchRelatedProducts: Boolean,
    $isWarehouseProduct: Boolean
  ) {
    product: productByPermalink(
      permalink: $permalink
      fetchRelatedProducts: $fetchRelatedProducts
      isWarehouseProduct: $isWarehouseProduct
    ) ${quickViewBaseQuery}
  }
`;

export const GET_RELATED_PRODUCTS_BY_PRODUCT_IDS_QUERY_QUICKVIEW = gql`
  ${INDIVIDUAL_PRODUCT_MAIN_DETAILS_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_PRICE_FRAGMENT}
  ${INDIVIDUAL_PRODUCT_PRODUCT_IMAGES_FRAGMENT}
  query PreloadProductsByMasterProductIds($productIds: [Int!]!) {
    products: masterProducts(productIds: $productIds) {
      ... on IndividualProduct {
        ...IndividualProductMainParts
        ...IndividualProductPriceParts
        ...IndividualProductImagesParts
        isAvailable
        requiredUserStatus
        styleCode
        styleLabel
      }
    }
  }
`;

export const useBaseProductQueryAndPreload = setupUseProductQueryAndPreload({
  productByPermalinkQuery: GET_PRODUCT_BY_PERMALINK_QUERY,
  productByProductIdQuery: GET_PRODUCT_BY_PRODUCT_ID_QUERY,
  preloadRelatedProductsByProductIdsQuery:
    GET_RELATED_PRODUCTS_BY_PRODUCT_IDS_QUERY,
  retrieveRelatedProductsCheck: () => true,
});

export const useBaseProductQueryAndPreloadQuickView =
  setupUseProductQueryAndPreload({
    productByPermalinkQuery: GET_PRODUCT_BY_PERMALINK_QUERY_QUICKVIEW,
    preloadRelatedProductsByProductIdsQuery:
      GET_RELATED_PRODUCTS_BY_PRODUCT_IDS_QUERY_QUICKVIEW,
  });

const getUseProductQueryAndPreload =
  (useBaseProductQueryAndPreloadHook) => (inputOptions) => {
    const queryResponse = useBaseProductQueryAndPreloadHook(inputOptions);
    const { masterProductId, permalink, ...options } = inputOptions;

    debug('running');

    const productFields = useLoadProductFields({
      masterProductId:
        masterProductId || queryResponse?.product?.masterProductId,
      permalink,
      params: {
        retailDiscounting:
          options?.additionalQueryVariables?.useRetailPriceForDiscount,
      },
      initialProduct: inputOptions?.initialProduct,
      productFields: [
        'promos',
        'style_code',
        'style_label',
        'related_product_id_object_list',
      ],
    });

    // `initialProduct` is only present on bundle items. We dont' need promos for those
    if (inputOptions?.initialProduct) {
      return queryResponse;
    }

    delete productFields.masterProductId;
    delete productFields.permalink;

    return {
      ...queryResponse,
      product: queryResponse.product
        ? {
            ...queryResponse.product,
            ...productFields,
          }
        : null,
    };
  };

export const useProductQueryAndPreload = getUseProductQueryAndPreload(
  useBaseProductQueryAndPreload
);

export const useProductQueryAndPreloadQuickView = getUseProductQueryAndPreload(
  useBaseProductQueryAndPreloadQuickView
);
