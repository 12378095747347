import { useEffect, useMemo, useRef, useState } from 'react';

import logger from '~/shared/utils/logger';
import { useProductActions } from '~/techstyle-shared/react-products';

const debug = logger.extend('useLoadProductFields');

const defaultParams = {
  page: 1,
  size: 1,
  // `fields` is what is requested from ElasticSearch
  fields: [
    'active_featured_product_location_code_list',
    'active_featured_product_location_id_list',
    'available_quantity_master',
    'available_quantity_ca',
    'default_product_category_id',
    'default_product_catagory_label',
    'default_unit_price',
    'group_code',
    'image_view_list',
    'item_number',
    'label',
    'lead_only_flag',
    'master_product_id',
    'permalink',
    'product_category_id_list',
    'retail_unit_price',
    'sale_unit_price',
    'vip_elite_only_flag',
    'date_expected',
    'date_preorder_expires',
    'tag_id_list',
    'tag_label_list',
    'token_redemption_quantity',
    'available_quantity_preorder_master',
    'available_quantity_preorder_master_default',
    'available_quantity_preorder_any_profile',
    'featured_product_location_id_list',
    'product_type_id',
    'related_product_id_object_list',
    'master_store_group_id',
    'is_token',
    'variant_pricing_id_object_list',
  ],
  // `productFields` is what will be returned from Tier1
  productFields: ['master_product_id', 'permalink'],
};

// Gets user-specific `promos` from Tier1 so product can be cached in CDN.
// TODO: Request at same time as graphQL query above. This will require
//       a new Tier1 endpoint to get product or promos by permalink
export default function useLoadProductFields({
  initialProduct,
  masterProductId,
  permalink,
  params: inputParams = {},
  options: inputOptions = {},
  productFields: additionalProductFields = [],
}) {
  const actions = useProductActions();
  const [currentProduct, setCurrentProduct] = useState({});
  const productsCache = useRef(new Map());

  let collatedProductFields = inputParams?.productFields
    ? [...defaultParams.productFields, ...inputParams.productFields]
    : defaultParams.productFields;

  collatedProductFields = [
    ...collatedProductFields,
    ...additionalProductFields,
  ];

  const params = {
    ...defaultParams,
    masterProductIds: masterProductId ? [masterProductId] : null,
    includeOutOfStock: true,
    ...inputParams,
    productFields: collatedProductFields,
  };
  const options = {
    apiVersion: 2,
    ...inputOptions,
  };

  const optionsMatch = JSON.stringify(options);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cachedParams = useMemo(() => params, [JSON.stringify(params)]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cachedOptions = useMemo(() => options, [optionsMatch]);

  const currentMasterProductId = useRef(masterProductId);

  useEffect(() => {
    const load = async () => {
      const result = await actions.loadProducts(cachedParams, cachedOptions);
      const product = result?.payload?.products?.[0];
      if (product) {
        debug('Caching promos for %s', product.masterProductId);
        productsCache.current.set(product.masterProductId, product);
        // NOTE: only set if this was the most recently requested product
        // ie: avoid race condition from user quickly selecting colors
        if (currentMasterProductId.current === product.masterProductId) {
          debug('setCurrentProduct %s', product.masterProductId);
          setCurrentProduct(product);
        }
      }
    };

    // Don't fetch unless we have a masterProductId and this isn't a bundle's
    // item (initialProduct is only present for bundle items).
    // TODO: also allow fetching by permalink
    if (masterProductId && !initialProduct) {
      debug('Fetching promos for %s', masterProductId);
      currentMasterProductId.current = masterProductId;
      load();
    }
  }, [actions, cachedParams, cachedOptions, initialProduct, masterProductId]);

  return currentProduct || {};
}
